import ts from './tellStories.module.css';
import React from 'react';


export const TellStories = ({ handleWaitListPopupOpen }) => {


    return (
        <section className={ts.section}>
            <div className={ts.box}>

                <div className={ts.img}>
                    <div className={ts.imgBox}>
                        <h2 className={ts.h}>Tell your stories to&nbsp;people all over the world</h2>
                        <button onClick={() => handleWaitListPopupOpen('author')} className={ts.btn} type='button'>Find out more</button>
                    </div>

                </div>

            </div>
        </section>
    )
};
