import React from "react";
import ap from './audioPlayer.module.css';
import { InsidePlayer } from "../InsidePlayer/insidePlayer";
import RemoLeftImg from '../../assets/images/audioImages/RemoLeftImg.svg';
import { PlaySong } from "../../assets/icons/icons";
import RemoRightImg from '../../assets/images/audioImages/RemoRightImg.svg';
import { Pause } from "../../assets/icons/icons";
export const AudioPlayer = () => {
    return (
        <div className={ap.section}>
            <div className={ap.image}>
                <div className={ap.blur}>
                    <InsidePlayer
                        trackIndex={0}
                        pause={<Pause color={"#fff"} />}
                        color='insidePlayerColor1'
                        style='insidePlayerStyle1'
                        colorBottomRange={"#777"}
                        colorTopRange={"#fff"}
                        bckgrnd='insidePlayerBack1'
                        remoteLeft={RemoLeftImg}
                        play={<PlaySong color={'#fff'}/>}
                        remoright={RemoRightImg}
                        lineStyle="playerLineYes"
                    />
                </div>
            </div>
        </div>
    )
};
