import React from 'react';
import cs from './categorie.module.css';

export const Categorie = ({ h, titles, bckgrnd, txt, img, id }) => {
    return (
        <div className={`${cs.box}`} key={id}>

            <div className={`${cs.boxWrapper} ${bckgrnd}`}>
                <div className={`${cs.boxInside}`}>
                    <div className={`${cs.texts}`}>
                        <span className={`${cs.h} ${txt}`}>{h}</span>
                        {/* <span className={`${cs.titles} ${txt}`}>{titles}</span> */}
                    </div>

                    <img src={img} className={cs.img} alt="" />
                </div>
            </div>
        </div>
    );
};