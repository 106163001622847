import fzs from './FourZones.module.css';
import { InsidePlayer } from '../InsidePlayer/insidePlayer';
import ladyInSwimmingSuit from '../../assets/images/layers/ladyLayerNew.png';
import { Podcasts } from '../podcasts/Podcasts';
import RemoLeftImgRed from '../../assets/images/audioImages/RemoLeftRed.svg';
import RemoRightImgRed from '../../assets/images/audioImages/RemoRightImgRed.svg';
import { Pause, PlaySong } from '../../assets/icons/icons';
export const FourZones = () => {

    return (
        <section className={fzs.section}>

            <img className={fzs.ladyLayer} src={ladyInSwimmingSuit} alt='' />

            <div className={fzs.smallCircle}></div>

            <div className={fzs.bigCircle}></div>

            <div className={fzs.boxOfFour}>
                <div className={fzs.upperBox}>

                    <div className={fzs.article}>
                        <h3 className={fzs.h3}>Read</h3>
                        <p className={fzs.p}>Great written stories illuminate the parts of&nbsp;yourself you may have lost touch with or&nbsp;have yet to&nbsp;discover. They give you ideas &amp;&nbsp;meaning and affirm that you are never alone. They empower your desires, turn on&nbsp;your mind and kindle your fantasies.</p>
                        {/* <button className={fzs.aBtn}>Show top 10&nbsp;text stories</button> */}
                    </div>

                    <Podcasts />
                </div>

                <div className={fzs.bottomBox}>
                    <InsidePlayer
                        trackIndex={1}
                         pause={<Pause color={"#B71F36"} />}
                        color='insidePlayerColor2'
                        style='insidePlayerStyle2'
                        colorBottomRange={"#B71F36"}
                        colorTopRange={"red"}
                        bckgrnd='insidePlayerBack2'
                        remoteLeft={RemoLeftImgRed}
                         play={<PlaySong color={'#B71F36'}/>}
                        remoright={RemoRightImgRed}
                        lineStyle='playerLineNo'
                    />
                    <div className={fzs.articleBottom}>
                        <h4 className={fzs.h4}>Listen</h4>
                        <p className={fzs.p2}>Test this audio story &amp;&nbsp;feel how it&nbsp;takes you into the world of&nbsp;your fantasies and sexual desires</p>
                    </div>
                </div>
            </div>

        </section >
    );
};