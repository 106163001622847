import React from "react";
import gcs from './gridCard.module.css';
import { EyeIcon } from "../../../assets/icons/icons";

import './gridCard.css'

export const GridCard = ({ img, num, viewers, id, gridClassName }) => {

    return (
        <div className={`${gcs.box} grid-card_type_${id}`} key={id}>
            <img className={`${gcs.img} ${gridClassName}`} src={img} alt="" />
            <span className={gcs.num}>{num}</span>
            {/* <div className={gcs.viewBox}>
                <EyeIcon width='16px' height='11px' fill='white' />
                <span className={gcs.viewers}>{viewers}</span>
            </div> */}
        </div>
    )
};