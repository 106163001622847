import ps from './podcasts.module.css';
import { Podcast } from './podcast/podcast';
import { PodcastData } from '../../assets/utils/constants';
import { motion } from "framer-motion";
import { useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import './podcasts.css'


export const Podcasts = () => {

  const [my_swiper, set_my_swiper] = useState({});

    const [isOpen, setOpen] = useState({});

    function handleClick(i) {
        setOpen({ ...isOpen, [i]: isOpen[i] ? !isOpen[i] : true });
    };

    return (
      <section className={ps.box}>
        <Swiper
          // effect={"coverflow"}
          // grabCursor={true}
          // centeredSlides={false}
          // slidesPerView={3}
          // coverflowEffect={{
          //   rotate: 50,
          //   stretch: 0,
          //   depth: 100,
          //   modifier: 2,
          //   slideShadows: false,
          // }}
         
          className="mySwiper"

          slidesPerView={"auto"}
          spaceBetween={0}
          pagination={{
              clickable: true,
              dynamicBullets: true,
              dynamicMainBullets: 1,
          }}
          mousewheel={{
              forceToAxis: true,
          }}

          modules={[Mousewheel]}
          preventInteractionOnTransition={true}
          // className="process__cards process__cards_mobile"
          onInit={(ev) => {
              set_my_swiper(ev)
          }}
        >
          {PodcastData.map((podcast, index) => {
            return (
              <SwiperSlide
              className='podcasts__card'
              >
                <Podcast
                  initial={{
                    paddingBottom: "14px",
                  }}
                  animate={{
                    paddingBottom: !isOpen[index] ? "14px" : "0px",
                  }}
                  transition={{ duration: 0.3, type: "tween" }}
                  extraClassName={podcast.extraClassName}
                  extraClassTxt={podcast.extraClassTxt}
                  h={podcast.h}
                  author={podcast.author}
                  img={podcast.img}
                  tags={podcast.tags}
                  fill={podcast.fill}
                  viewers={podcast.viewers}
                  episodes={podcast.episodes}
                  key={podcast.id}
                  index={index}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </section>
    );
};