import fs from './footer.module.css';
import React from 'react';
import { useDispatch } from 'react-redux';
import { EyeIcon, Instagram, Facebook, Twitter } from '../../assets/icons/icons';
import { Link } from 'react-router-dom';

export const Footer = () => {

    const dispatch = useDispatch();

    return (
        <section className={fs.section}>
            <nav className={fs.box}>

                <ul className={fs.leftBox}>
                    <li>
                        <Link to={'/'} className={`${fs.btn}`}>{<EyeIcon width={20.02} height={14} fill='#000' />}</Link>
                    </li>
                    <li className={fs.liNotLink}>
                        <p className={`${fs.btn}`}>© 2024</p>
                    </li>
                    {/* <li >
                        <Link to={'/'} className={`${fs.btn}`}>Home</Link>
                    </li>
                    <li >
                        <Link to={'/'} className={`${fs.btn}`}>About</Link>
                    </li>
                    <li >
                        <Link to={'/'} className={`${fs.btn}`}>Subscriptions</Link>
                    </li>
                    <li >
                        <Link to={'/'} className={`${fs.btn}`}>FAQ</Link>
                    </li> */}
                </ul>

                <ul className={fs.rightBox}>
                    <li>
                        <a href='https://instagram.com/muchi.io' className={`${fs.a}`} rel="noreferrer" target="_blank">{<Instagram width={40} height={40} />}</a>
                    </li>
                    <li>
                        <a href='https://www.facebook.com/profile.php?id=100092561651921&mibextid=LQQJ4d' className={`${fs.a}`} rel="noreferrer" target="_blank">{<Facebook width={40} height={40} />}</a>
                    </li>
                    {/* <li>
                        <a href='' className={`${fs.a}`} target blank>{<Twitter width={40} height={40} />}</a>
                    </li> */}
                </ul>

            </nav>
        </section >
    )
};