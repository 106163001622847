import { useEffect, useRef, useState } from "react";

import validator from 'validator'




import "./WaitlistPopup.css";
import { CheckBoxIcon, CloseIcon, EyeIcon } from "../../assets/icons/icons";
import Input from "../Input/Input";
import MiniPreloader from "../MiniPreloader/MiniPreloader";
import mainApi from "../../assets/api/MainApi";
import { sendMetriс } from "../../assets/utils/utils";

function WaitlistPopup({ isOpen, setOpen, popupName, defaultUserType }) {

    const [step, setStep] = useState(0)

    const [form, setForm] = useState({
        user_type: defaultUserType ? defaultUserType : 'reader',
        name: '',
        email: '',
        checkbox: false,
    })

    const [formValid, setFormValid] = useState({
        email: {},
    })

    const overlay = useRef();

    function handleClose() {
        setOpen(prevState => ({
            ...prevState,
            [popupName]: false,
        }))
        setTimeout(() => {
            setStep(0)
            hendleFormReset()
        }, 300);

    }

    useEffect(() => {
        function handleOverlayClose(evt) {
            if (overlay.current === evt.target) {
                handleClose();
            }
        }

        document.addEventListener("mousedown", handleOverlayClose);
        return () => {
            document.removeEventListener("mousedown", handleOverlayClose);
        };
    });



    function changeUserType(type) {
        setForm(prevValue => ({
            ...prevValue,
            user_type: type,
        }))
    }

    function handleChange(e) {
        const input = e.target;
        const name = input.name;
        let value = input.value;

        switch (name) {

            case 'email':
                if (!value) {
                    setFormValid(prevValue => ({
                        ...prevValue,
                        email: {
                            errorMassage: '',
                            validState: false
                        }
                    }))
                }
                if (value.length >= 2) {
                    if (validator.isEmail(value)) {
                        setFormValid(prevValue => ({
                            ...prevValue,
                            email: {
                                errorMassage: '',
                                validState: true
                            }
                        }))
                    } else {
                        setFormValid(prevValue => ({
                            ...prevValue,
                            email: {
                                errorMassage: (!e.target.validity.valid ? e.target.validationMessage : 'Invalid email'),
                                validState: false
                            }
                        }))
                    }
                }
                setForm(prevValue => ({
                    ...prevValue,
                    [name]: value
                }))
                break;


            default:
                setForm(prevValue => ({
                    ...prevValue,
                    [name]: value
                }))
                break;
        }

    }

    const isFormVlid = form.name && form.checkbox && form.email && form.user_type && formValid.email.validState

    const [isPreloaderVisible, setPreloaderVisible] = useState(false)
    function handleSend() {
        setPreloaderVisible(true)

        mainApi.sendWaitlistForm(({
            email: form.email,
            name: form.name,
            type: form.user_type,
        }))
            .then((res) => {
                sendMetriс('reachGoal', 'SEND_WAITLIST_FORM')
                console.log(res)
                setStep(1)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaderVisible(false)
            })
    }

    function hendleFormReset() {
        setForm({
            user_type: defaultUserType ? defaultUserType : 'reader',
            name: '',
            email: '',
            checkbox: false,
        })
        setFormValid({
            email: {},
        })
    }

    useEffect(() => {
        setForm(prevState => ({
            ...prevState,
            user_type: defaultUserType,
        }))
    }, [defaultUserType])

    return (
        <div
            className={`waitlist-popup ${isOpen ? "waitlist-popup_opened" : ""}`}
            ref={overlay}
        // onTouchStart={handleClose}
        >

            <div className={`waitlist-popup__container waitlist-popup__container_step_${step}`}>
                <button
                    className="waitlist-popup__close-button"
                    type="button"
                    aria-label="Закрыть попап"
                    onClick={handleClose}
                >
                    <CloseIcon />
                </button>

                {step === 0 ?
                    <>
                        <h3 className="waitlist-popup__title">Join Waitlist</h3>
                        <p className="waitlist-popup__subtitle">Submit the form to&nbsp;get priority access to&nbsp;the beta version of&nbsp;the platform</p>
                        <div className="waitlist-popup__selectors">
                            <button className={`waitlist-popup__selector ${form.user_type === 'reader' ? 'waitlist-popup__selector_selected' : ''}`} onClick={() => {
                                changeUserType('reader')
                            }} type="button">
                                Reader
                            </button>
                            <button className={`waitlist-popup__selector ${form.user_type === 'author' ? 'waitlist-popup__selector_selected' : ''}`} onClick={() => {
                                changeUserType('author')
                            }} type="button">
                                Author
                            </button>
                        </div>
                        <div className="waitlist-popup__inputs">
                            <Input
                                label={'Name'}
                                value={form.name}
                                handleChange={handleChange}
                                name={'name'}
                            />
                            <Input
                                label={'Email'}
                                value={form.email}
                                handleChange={handleChange}
                                name={'email'}
                                error={formValid.email}
                            />
                        </div>
                        <div className="waitlist-popup__checkbox" onClick={() => {
                            setForm(prevValue => ({
                                ...prevValue,
                                checkbox: !prevValue.checkbox,
                            }))
                        }}>
                            <button className={`waitlist-popup__checkbox-btn ${form.checkbox ? 'waitlist-popup__checkbox-btn_cheked' : ''}`} >
                                <div className="waitlist-popup__checkbox-border"></div>
                                <CheckBoxIcon
                                    mainClassName={'waitlist-popup__checkbox-icon'}
                                    strokeClassName={`waitlist-popup__checkbox-icon-stroke`}
                                />
                            </button>
                            <p className="waitlist-popup__checkbox-text">I&nbsp;consent to&nbsp;the processing of&nbsp;personal data, as&nbsp;well as&nbsp;to&nbsp;their use for marketing purposes.</p>
                        </div>
                        <button className={`waitlist-popup__submit ${isFormVlid ? '' : 'waitlist-popup__submit_inactive'}`} onClick={handleSend}>
                            {isPreloaderVisible ? <MiniPreloader isLinkColor={true} /> : 'Send'}
                        </button>
                    </>
                    : null}

                {step === 1 ?
                    <>
                        <EyeIcon mainClassName={'waitlist-popup__success-icon'} width={142} height={98} fill={'#FFFFFF'} />
                        <p className="waitlist-popup__success">Your application for the Waitlist has been successfully submitted, as&nbsp;soon as&nbsp;the beta version is&nbsp;available, we&nbsp;will send you an&nbsp;email.</p>
                    </>
                    : null}

            </div>
        </div >
    );
}

export default WaitlistPopup;