import pinkImg from '../../assets/images/podcasts/Podcast1.png';
import brownImg from '../../assets/images/podcasts/Podcast2.png';
import darkBrownImg from '../../assets/images/podcasts/Podcast3.png';
import cutImg from '../../assets/images/podcasts/Podcast4.png';

import gridCard1 from '../../assets/images/series2.png';
import gridCard2 from '../../assets/images/series1.png';
import gridCard3 from '../../assets/images/series3.webp';

import categorieClassic from '../../assets/images/categoriesCards/categorie1.png';
import categorieBdsm from '../../assets/images/categoriesCards/bdsm.webp';
import categorieDomination from '../../assets/images/categoriesCards/domination.webp';
import categorieLighterotic from '../../assets/images/categoriesCards/lighterotic.webp';
import categorieOnlygirls from '../../assets/images/categoriesCards/onlygirls.webp';
import categorieThreesome from '../../assets/images/categoriesCards/threesome.webp';


export const MAIN_URL = "https://app.muchi.io"


export const PodcastData = [
   
    {
        extraClassName: 'podcastBrown',
        extraClassTxt: 'podcastBrownTxt',
        h: 'The Pisces',
        author: 'by Chalung Soon',
        img: brownImg,
        tags: '#bdsm #light #modern #intrigui',
        fill: '#B0663C',
        viewers: '1,223,750',
        episodes: '3 episode',
        id: 2,
    },
    {
        extraClassName: 'podcastDarkBrown',
        extraClassTxt: 'podcastDarkBrownTxt',
        h: 'How to Soften a Leather Collar at Home',
        author: 'by Mamako',
        img: darkBrownImg,
        tags: '#girls-only #guide #housekeepin',
        fill: '#805A4F',
        viewers: '23,750',
        episodes: '1 episode',
        id: 3,
    },
    {
        extraClassName: 'podcastCut',
        extraClassTxt: 'podcastCutTxt',
        h: 'My story',
        author: 'by Sarah Pie',
        img: cutImg,
        tags: '#workplace',
        fill: '#030303',
        viewers: '650',
        episodes: '',
        id: 4,
    },
    {
        extraClassName: 'podcastPink',
        extraClassTxt: 'podcastPinkTxt',
        h: 'Girl Meets Boy',
        author: 'by Lemon Dolche',
        img: pinkImg,
        tags: '#girls-only #guide #housekeepin',
        fill: '#BD3C65',
        viewers: '23,750',
        episodes: '1 episode',
        id: 1,
    },
];

export const GridData = [
    {
        img: gridCard1,
        num: '1',
        viewers: '1,223,750',
        id: 1,
        gridClassName: 'gridClassName1',
    },
    {
        img: gridCard2,
        num: '2',
        viewers: '1,223,750',
        id: 2,
        gridClassName: 'gridClassName2',
    },
    {
        img: gridCard3,
        num: '3',
        viewers: '1,223,750',
        id: 3,
        gridClassName: 'gridClassName3',
    },
];

export const CategoriesData = [
    {
        h: 'Classic',
        titles: '23,750 titles',
        img: categorieClassic,
        bckgrnd: 'categorieClassic',
        txt: 'catText1',
        id: 1,
    },
    {
        h: 'Domination',
        titles: '23,750 titles',
        img: categorieDomination,
        bckgrnd: 'categoriePurple',
        txt: 'catText2',
        id: 2,
    },
    {
        h: 'BDSM',
        titles: '23,750 titles',
        img: categorieBdsm,
        bckgrnd: 'categorie3',
        txt: 'catText1',
        id: 3,
    },
    {
        h: 'Light Erotic',
        titles: '23,750 titles',
        img: categorieLighterotic,
        bckgrnd: 'categoriePurple',
        txt: 'catText2',
        id: 4,
    },
    {
        h: 'Only Girls',
        titles: '23,750 titles',
        img: categorieOnlygirls,
        bckgrnd: 'categorieClassic',
        txt: 'catTextGirls',
        id: 5,
    },
    {
        h: 'Threesome',
        titles: '23,750 titles',
        img: categorieThreesome,
        bckgrnd: 'categorie3',
        txt: 'catText3some',
        id: 6,
    },
]

export const faqTexts = [
    {
        question: 'When is the release date',
        answer: [
            "July 2024",
        ],
        id: 3,
    },
    {
        question: 'Why join Waitlist?',
        answer: [
            "Muchi is currently under development, with a planned release date of July 2024. We are now accepting early access applications through our waitlist form. Waitlisted users will have the unique opportunity to receive discounted subscriptions and work with our team of marketing creators.",
        ],
        id: 1,
    },
    {
        question: "How to publish a story?",
        answer: [`Currently, expressing interest in the platform requires submitting a request to the Waitlist. When the beta version is released, to publish your story on Muchi, you'll need to create an account and select the "Author" option during subscription. From there, you can upload your stories and earn revenue from paid users' unique reads, as well as commissions from new subscriptions. You'll also have the ability to promote your content and interact with your audience.`],
        id: 2,
    },

    {
        question: "How to make money on Muchi?",
        answer: [
            "You can earn revenue from unique reads by paid users, as well as commissions from new subscriptions that result from your content.",
        ],
        id: 4,
    },
    // {
    //     question: "Can I subscribe?",
    //     answer: [
    //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
    //     ],
    //     id: 5,
    // },
    // {
    //     question: 'How to subscribe?',
    //     answer: [
    //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
    //     ],
    //     id: 6,
    // },
];