import faq from './Faq.module.css';
import React from 'react';
import { useState } from "react";
import { motion } from "framer-motion";
import { Answer } from './Answer/Answer';
import { faqTexts } from '../../assets/utils/constants';

export const Faq = () => {

    const [isOpen, setOpen] = useState({});

    function handleClick(i) {
        setOpen({ ...isOpen, [i]: isOpen[i] ? !isOpen[i] : true });
    }

    return (
        <section className={faq.section}>

            <h2 className={faq.faq}>FAQ</h2>

            <hr className={`${faq.line}`} />

            <ul className={faq.burger}>
                {faqTexts.map((item, i) => (
                    <motion.li
                        className={faq.faq__questionCard}
                        key={`question-${i}`}
                        initial={{
                            paddingBottom: "14px",
                        }}
                        animate={{
                            paddingBottom: !isOpen[i] ? "14px" : "0px",
                        }}
                        transition={{ duration: 0.3, type: "tween" }}
                    >
                        <div className={faq.faq__questionHeading}
                            onClick={() => handleClick(i)}
                        >
                            <p className={isOpen[i] ? `${faq.faq__question} ${faq.faq__questionOpened}` : `${faq.faq__question}`}>{item.question}</p>
                            <motion.div
                                className={!isOpen[i] ? `${faq.iconPlus}` : `${faq.iconMinus}`}
                                transition={{ duration: 0.3, type: "tween" }}
                            >
                            </motion.div>
                        </div>
                        <div className={faq.faq__answer}>
                            <Answer answer={item.answer} isOpen={isOpen[i]} />
                        </div>
                    </motion.li>
                ))}
            </ul>

        </section>
    )
};
