import cs from './categories.module.css';
import { CategoriesData } from '../../assets/utils/constants';
import { Categorie } from './Categorie/Categorie';

export const Categories = () => {
    return (
        <section className={cs.section}>
            <div className={cs.box}>
                <h2 className={cs.h}>Categories</h2>
                <div className={cs.gridBox}>
                    {CategoriesData.map((categorie, index) => {

                        return (
                            <Categorie
                                h={categorie.h}
                                titles={categorie.titles}
                                bckgrnd={categorie.bckgrnd}
                                txt={categorie.txt}
                                img={categorie.img}
                                id={categorie.id}
                                key={categorie.id}
                                index={index}
                            />
                        );
                    })}
                </div>

            </div>

        </section >
    );
};