import { useState } from 'react';
import s from './series.module.css';
import kissingBlur from '../../assets/images/blurSeriesSection.webp';
import { EyeIcon } from '../../assets/icons/icons';
import { GridCard } from './gridCard/gridCard';
import { GridData } from '../../assets/utils/constants';

import { PlaySong } from '../../assets/icons/icons';

export const Series = () => {

    const [active, setActive] = useState();

    return (
        <section className={s.section}>

            <div className={s.bigBox}>

                <div className={s.boxBlur}>
                    <div className={s.insideBlur}>

                        <div className={s.seasonChoser}>

                            <div className={s.seasonBox}>
                                <p className={`${s.season} ${s.seasonActive}`}>Season 1</p>
                                <p className={`${s.season}`}>Season 2</p>
                                <p className={`${s.season}`}>Season 3</p>
                            </div>
                            <hr className={`${s.line}`} />
                            {/* <hr className={`${s.lineActive}`} /> */}

                        </div>

                        <p className={s.p}>Watch candid series
                            with beautiful erotica
                            and sensual plot
                        </p>

                        <button className={s.controller}><PlaySong className={s.controllerColor} /></button>

                        <div className={s.viewBox}>
                            {/* <div className={s.viewBoxLeft}>
                                <EyeIcon width='16px' height='11px' fill='white' />
                                <span className={s.viewers}>1,223,750</span>
                            </div> */}
                            <span className={s.episodes}>3 episodes</span>
                        </div>

                    </div>
                </div>

                {/* Карточки для грида */}
                <div className={s.boxCards}>
                    {GridData.map((card, index) => {
                        return (
                            <GridCard
                                img={card.img}
                                num={card.num}
                                viewers={card.viewers}
                                id={card.id}
                                key={card.id}
                                index={index}
                                gridClassName={card.gridClassName}
                            />
                        );
                    })}
                </div>

            </div>

        </section >
    );


};