import introStyles from './intro.module.css';
import React from 'react';
import { Header } from '../header/Header';

import bg_img_pc from '../../assets/images/womanDesktop.webp'
import bg_img_tabalet from '../../assets/images/womaLaptop.webp'
import bg_img_mobile from '../../assets/images/womanMob.webp'



import './intro.css'

export const Intro = ({ handleWaitListPopupOpen }) => {


    return (
        <div className={introStyles.mainContent}>
            <section className={introStyles.mainBox}>


                <div className={introStyles.imgBackMob}> {/* Градиент для мобилки (сверху страницы) */}
                </div>
                <img className='intro__bg-img intro__bg-img_type_pc' src={bg_img_pc} alt='' />
                <img className='intro__bg-img intro__bg-img_type_tablet' src={bg_img_tabalet} alt='' />
                <img className='intro__bg-img intro__bg-img_type_mobile' src={bg_img_mobile} alt='' />




                <div className={introStyles.box}>
                    <Header handleWaitListPopupOpen={handleWaitListPopupOpen} />

                    <h2 className={introStyles.heading}>Ignite Your Passion:
                        Turn to&nbsp;a&nbsp;world
                        of&nbsp;Sensual Stories</h2>
                </div>

            </section>

            {/*голубой кргу за ноутом*/}
            <div className={introStyles.ellipse} />

            <div className={introStyles.circle}></div>

        </div>
    )
}