import React from "react";
import fs from './feelArticle.module.css';
import tongueLipsImg from '../../assets/images/tongueLipsHands.webp';

export const FeelArticle = () => {

    return (
        <section className={fs.section}>

            <div className={fs.circle}></div>

            <img className={fs.img} src={tongueLipsImg} alt="" />

            <div className={fs.textBox}>

                <h2 className={fs.h}>Feel</h2>
                <p className={fs.p}>Muchi is&nbsp;a&nbsp;platform where users can explore their sexuality and desires through a&nbsp;wide range of&nbsp;sexual themes through audio and written stories while supporting and empowering content creators.</p>
            </div>

        </section>
    )
};