import React from "react";
import ip from "./InsidePlayer.module.css";
import { useState, useEffect, useRef } from "react";
import Backdrop from "./Backdrop";
import tracks from "../InsidePlayer/tracks";
import { sendMetriс } from "../../assets/utils/utils";

export const InsidePlayer = ({
  style,
  color,
  bckgrnd,
  remoteLeft,
  remoright,
  play,
  pause,
  lineStyle,
  trackIndex,
  colorTopRange,
  colorBottomRange,
}) => {
  let [trackProgress, setTrackProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const { song, author, audioSrc, default_duration } = tracks[trackIndex];

  const audioRef = useRef(new Audio(audioSrc));

  useEffect(() => {
    if (!audioRef || !audioRef.current) return
    audioRef.current.volume = 0.1
    console.log(audioRef)
  }, [audioRef])


  const intervalRef = useRef();
  const isReady = useRef(false);

  const { duration } = audioRef.current;

  // useEffect(() => {
  //   if (audioRef && audioRef.current) {
  //     setTimeout(() => {
  //       audioRef.current.play();
  //     }, 100);

  //     console.log(audioRef, audioRef.current)
  //   } else return

  // }, [audioRef])

  const currentPercentage = duration
    ? `${(trackProgress / duration) * 100}%`
    : "0%";
  const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, ${colorTopRange}), color-stop(${currentPercentage}, ${colorBottomRange}))
  `;

  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        setTrackProgress(0);
      } else {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, [1000]);
  };

  const onScrub = (value) => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  const onScrubEnd = () => {
    // If not already playing, start
    if (!isPlaying) {
      setIsPlaying(true);
    }
    startTimer();
  };

  const skipForward = () => {
    audioRef.current.currentTime += 30;
  };

  const skipBackward = () => {
    audioRef.current.currentTime -= 30;
  };
  useEffect(() => {
    if (isPlaying) {
      sendMetriс('reachGoal', 'LISTEN_AUDIO')
      audioRef.current.play();
      startTimer();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying, audioRef]);

  // Handles cleanup and setup when changing tracks
  useEffect(() => {
    audioRef.current.pause();
  }, [trackIndex]);

  useEffect(() => {
    // Pause and clean up on unmount
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);
  const minutes = Math.floor(trackProgress / 60);
  const seconds = trackProgress % 60;
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  // format as MM:SS
  const result = `${padTo2Digits(minutes)}:${padTo2Digits(Math.round(seconds))}`;

  const minutesAll = Math.floor(duration / 60);
  const secondsAll = duration % 60;
  // format as MM:SS
  const resultAll = `${padTo2Digits(minutesAll)}:${Math.round(
    padTo2Digits(secondsAll)
  )}`;
  console.log()

  return (
    <div className={`${ip.section} ${style}`}>
      <div className={ip.naming}>
        <span className={`${ip.song} ${color}`}>{song}</span>
        <span className={`${ip.author} ${color}`}>{author}</span>
      </div>
      <div className={`${ip.line} ${lineStyle}`} />

      <div className={ip.controlls}>
        <button onClick={skipBackward} className={ip.controller}>
          <img src={remoteLeft} alt="" />
        </button>
        {isPlaying ? (
          <button className={ip.controller} onClick={() => setIsPlaying(false)}>
            {pause}
          </button>
        ) : (
          <button className={ip.controller} onClick={() => setIsPlaying(true)}>
            {play}
          </button>
        )}
        <button onClick={skipForward} className={ip.controller}>
          <img src={remoright} alt="" />
        </button>
      </div>

      <div className={ip.progressbarBox}>
        <div className={`${ip.progressbar} ${bckgrnd}`}></div>
        <input
          className={`${ip.fill} ${bckgrnd}`}
          type="range"
          value={trackProgress}
          step="1"
          min="0"
          max={duration ? duration : `${duration}`}
          onChange={(e) => onScrub(e.target.value)}
          onMouseUp={onScrubEnd}
          onKeyUp={onScrubEnd}
          style={{ background: trackStyling }}
        ></input>
        <div className={ip.time}>{result}</div>
        <div className={`${ip.time} ${ip.timeAll}`}>{duration ? resultAll : default_duration}</div>
      </div>
      <Backdrop
        trackIndex={trackIndex}
        activeColor={color}
        isPlaying={isPlaying}
      />
    </div>
  );
};
