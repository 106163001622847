import ms from "./modal.module.css";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";
import { useEffect, useState } from "react";
import { ModalOverlay } from "./ModalOverlay";
import { useSelector, useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { changePopupState } from "../../assets/services/actions/popup.jsx";
// import { CloseModal } from "../../assets/icons/icons";
import Checkbox from "@mui/material/Checkbox";
import { pink } from "@mui/material/colors";
import { useFormik } from "formik";
//сюда ведет портал
const rootForModal = document.getElementById("react-modals");

export const Modal = () => {

  const validate = (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = "Required";
    } else if (values.firstName.length > 15) {
      errors.firstName = "Must be 15 characters or less";
    }
    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      email: "",
    },
    validate,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const [chooseWriter, setChossedWriter] = useState(false);
  const [chooseAuthor, setChossedAuthor] = useState(false);
  const [checked, setChecked] = useState(false);

  const isPopupOpen = (state) => state.popupState.isPopupOpened;
  const isPopupOpened = useSelector(isPopupOpen);
  const dispatch = useDispatch();
    
   const handleChange = (event) => {
    setChecked(event.target.checked);
   };
  const popupCloseHandler = () => {
    dispatch(changePopupState(false));
  };
  const onModalClose = () => {
    popupCloseHandler(false);
  };
  useEffect(() => {
    const handleEscapeClose = (evt) => {
      if (evt.key === "Escape") {
        onModalClose();
      }
    };

    document.addEventListener("keyup", handleEscapeClose);
    return () => {
      document.removeEventListener("keyup", handleEscapeClose);
    };
  }, [popupCloseHandler]);

  return createPortal(
    <>
      <motion.section
        initial={{ scale: 0 }}
        animate={{
          scale: 1,
          translateX: -350,
          translateY: -350,
        }}
        transition={{
          type: "spring",
          stiffness: 100,
          damping: 20,
        }}
        className={`${ms.box} ${isPopupOpened ? ms.boxOpened : ""}`}
      >
        <div className={ms.inBox}>
          <h2 className={`${ms.heading}`}>Join Waitlist</h2>

          <button onClick={() => onModalClose()} className={ms.closeBtn}>
            {/* <CloseModal /> */}
          </button>

          <p className={ms.p}>
            Lorem ipsum dolor sit amet consectetur. Orci dolor augue
            sollicitudin fringilla quisio.
          </p>

          <form
            onSubmit={formik.handleSubmit}
            type="submit"
            className={ms.form}
          >
            <div className={ms.btnBox}>
              <div
                onClick={() => setChossedWriter(true)}
                className={`${ms.smallBtn} ${ms.btnGrey} ${
                  chooseWriter ? ms.btnPink : ""
                }`}
              >
                Writer
              </div>
              <div
                onClick={() => setChossedAuthor(true)}
                className={`${ms.smallBtn} ${ms.btnGrey} ${
                  chooseAuthor ? ms.btnPink : ""
                }`}
              >
                Author
              </div>
            </div>

            <div className={ms.formBox}>
              <input className={ms.input} placeholder="Name" />

              <input className={ms.input} placeholder="Email" />

              <div className={ms.contacts__checkbox_block}>
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  sx={{
                    color: pink[300],
                    "&.Mui-checked": {
                      color: pink[300],
                    },
                  }}
                />
                <div className={ms.contacts__checkboxtext}>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                </div>
              </div>

              <button className={ms.mainBtn}>Send</button>
            </div>
          </form>
        </div>
      </motion.section>

      <ModalOverlay popupCloseHandler={popupCloseHandler} />
    </>,
    rootForModal
  );
};

Modal.propTypes = {
  popupCloseHandler: PropTypes.func.isRequired,
};
