import { CHANGE_POPUP_STATE } from "../actions/popup.jsx";

const defaultState = {
    isPopupOpened: false,
}

export const popupStateReducer = (state = defaultState, action) => {

    switch (action.type) {

        case CHANGE_POPUP_STATE: {
            return {
                ...state,
                isPopupOpened: action.payload,
            }
        }        
        
        default: { return state; }
    }
}