import React, { useEffect } from "react";
import ip from './intro-player.module.css';

import { AudioPlayer } from "../AudioPlayer/audioPlayer";
import diagonalLayer from '../../assets/images/layers/diagonalVector.webp';
import fishLayer from '../../assets/images/layers/fishVector.webp';
import upDiagonal from '../../assets/images/layers/upDiagonal.png';

import './introPlayer.css'

export const IntroPlayer = () => {
    useEffect(() => {
        window.addEventListener('scroll', () => {
            document.body.style.setProperty('--scroll', window.pageYOffset / (document.body.offsetHeight - window.innerHeight));
        }, false);
    }, [])
    return (
        <section className={ip.section}>

            <img className={ip.upDiagonal} src={upDiagonal} alt="" />

            <div className="intro-player__banner-title"></div>
            
            <div className={ip.playerBox}>

                <AudioPlayer />

                <div className={`${ip.article} ${ip.aBtnBig}`}>
                    <h3 className={ip.h3}>Listen</h3>
                    <p className={ip.p}>Erotic audio is&nbsp;a&nbsp;special kind of&nbsp;immersion and enjoyment. It&nbsp;moves you to&nbsp;an&nbsp;extra sensory dimension, to&nbsp;a&nbsp;fantasy world of&nbsp;sexy stories. Relax and lose yourself in&nbsp;the moment as&nbsp;you listen to&nbsp;our arousing sex stories spoken to&nbsp;you.</p>
                    {/* <button className={ip.aBtn}>Show top 10&nbsp;audio stories</button> */}
                </div>

            </div>

            <img className={ip.fishLayer} src={fishLayer} alt="" />


            <img className={ip.diagonalLayer} src={diagonalLayer} alt="" />

        </section>
    )
}