import ps from './podcast.module.css';
import { EyeIcon } from '../../../assets/icons/icons';
import { useRef, useState, useEffect } from 'react';

import { motion } from "framer-motion";

export const Podcast = ({ extraClassName, extraClassTxt, h, author, img, tags, fill, viewers, episodes, id }) => {

    return (
        <div className={`${ps.box} ${extraClassName}`} key={id}>
            <div className={ps.boxInside}></div>

            <div className={ps.texts}>
                <h4 className={ps.h}>{h}</h4>
                <span className={ps.author}>{author}</span>
            </div>

            <img src={img} className={ps.img} alt=""  />

            <span className={`${ps.tags} ${extraClassTxt}`}>{tags}<div className={`${ps.blurGradient} ${extraClassName}`}></div></span>

            <div className={ps.viewersBigBox}>

                <div className={`${ps.viewersBox}`}>
                    {/* <EyeIcon width='16px' height='11px' fill={fill} />
                    <span className={`${ps.viewers} ${extraClassTxt}`}>{viewers}</span> */}
                </div>
                <span className={`${ps.episodes}`}>{episodes}</span>

            </div>
        </div >
    );
};