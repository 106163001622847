import React from 'react';
import headerStyles from './header.module.css';
import { EyeIcon } from '../../assets/icons/icons';
import { Logo } from '../../assets/icons/icons';
// import { useDispatch } from 'react-redux';
// import { changePopupState } from '../../assets/services/actions/popup';

export const Header = ({handleWaitListPopupOpen}) => {

    // const dispatch = useDispatch();

    // const handleBtnClick = () => { // просмотра попапа
    //     dispatch(changePopupState(true));
    // };

    return (
        <div className={headerStyles.box}>
            <div className={headerStyles.logoBox}>
                <Logo />
                <EyeIcon width={30} height={21} fill='#fff' />
            </div>
            <button onClick={() => handleWaitListPopupOpen('reader')} className={headerStyles.btn} type='button'>Join Waitlist</button>
        </div>
    )
}