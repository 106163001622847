import first from "../../assets/audio/MotoTripe.mp3";
import second from "../../assets/audio/ANUNUSUALCRUISE.mp3"

 const tracks = [
   {
     song: "Motorcycle trip",
     author: "by Muchi",
     audioSrc: first,
     default_duration: '20:17',
   },
   {
     song: "Unusual cruise",
     author: "by Muchi",
     audioSrc: second,
     default_duration: '10:45',

   },
 ];
export default tracks