import { useRef } from "react";
import { motion } from "framer-motion";
import as from './Answer.module.css';

export const Answer = ({ answer, isOpen }) => {
    const contentRef = useRef(null);

    return (
        <motion.article
            className={as.answer}
            ref={contentRef}
            initial={{
                height: "0px",
                marginBottom: "0px",
                opacity: 0,
                visibility: "hidden",
            }}
            animate={{
                height: isOpen ? contentRef.current.scrollHeight + "px" : "0px",
                // marginBottom: isOpen ? "40px" : "0px",
                opacity: isOpen ? 1 : 0,
                visibility: isOpen ? "visible" : "hidden",
            }}
            transition={{ duration: 0.3, type: "tween" }}
        >
            {answer.map((text, j) => (
                <p className={as.answerText} key={`answer-text-${j}`}>
                    {text}
                </p>
            ))}
        </motion.article>
    );
}
