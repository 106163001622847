import sbs from './subscriptions.module.css';
import React from 'react';
import { Heart } from '../../assets/icons/icons';
import sexySelfie from '../../assets/images/sexySelfie.png';


export const Subscriptions = ({ handleWaitListPopupOpen }) => {

    // const dispatch = useDispatch();

    // const handleBtnClick = () => { // просмотра попапа
    //     dispatch(changePopupState(true));
    // };

    return (
        <section className={sbs.section}>

            <div className={sbs.circle}></div>

            <img src={sexySelfie} alt="" className={sbs.selfie} />

            <div className={sbs.box}>
                <h2 className={sbs.h}>Subscriptions</h2>

                <div className={sbs.inBox}>
                    <div className={sbs.priceBox}>
                        <div className={sbs.conditionBox}>

                            <div className={sbs.smallConditionBox}>
                                <span className={sbs.for}>Reader</span>
                                <span className={sbs.type}>Basic</span>
                            </div>

                            <hr className={sbs.line} />

                            <span className={sbs.price}>Free</span>

                        </div>
                        <div className={sbs.benefitsBox}>
                            <div className={sbs.likeAndTxt}>
                                <Heart color='white' />
                                <span className={sbs.spanTxt}>For exploring the platform</span>
                            </div>

                            <div className={sbs.likeAndTxt}>
                                <Heart color='white' />
                                <span className={sbs.spanTxt}>Limited access to&nbsp;content</span>
                            </div>

                            <div className={sbs.likeAndTxt}>
                                <Heart color='white' />
                                <span className={sbs.spanTxt}>Variety of&nbsp;genres and exciting stories</span>
                            </div>

                        </div>
                    </div>
                    {/* */}
                    <div className={sbs.priceBox}>
                        <div className={sbs.conditionBox}>

                            <div className={sbs.smallConditionBox}>
                                <span className={`${sbs.for} ${sbs.purpleColor}`}>Reader</span>
                                <span className={`${sbs.type} ${sbs.purpleColor}`}>Advanced</span>
                            </div>

                            <hr className={`${sbs.line} ${sbs.purpleBackground}`} />

                            <div className={sbs.seconthird}>
                                <span className={`${sbs.price} ${sbs.purpleColor}`}>$10</span>
                                <span className={`${sbs.monthly}`}>monthly</span>
                            </div>

                        </div>
                        <div className={sbs.benefitsBox}>
                            <div className={sbs.likeAndTxt}>
                                <Heart color='#B48ABB' />
                                <span className={sbs.spanTxt}>For conscious readers</span>
                            </div>

                            <div className={sbs.likeAndTxt}>
                                <Heart color='#B48ABB' />
                                <span className={sbs.spanTxt}>Access to&nbsp;subscription-based content</span>
                            </div>

                            <div className={sbs.likeAndTxt}>
                                <Heart color='#B48ABB' />
                                <span className={sbs.spanTxt}>Access to&nbsp;purchase single episodes</span>
                            </div>

                            <div className={sbs.likeAndTxt}>
                                <Heart color='#B48ABB' />
                                <span className={sbs.spanTxt}>Access to&nbsp;limited Muchi content</span>
                            </div>

                            <div className={sbs.likeAndTxt}>
                                <Heart color='#B48ABB' />
                                <span className={sbs.spanTxt}>Access to&nbsp;moderating system</span>
                            </div>

                            <div className={sbs.likeAndTxt}>
                                <Heart color='#B48ABB' />
                                <span className={sbs.spanTxt}>Whitelist access to&nbsp;new features</span>
                            </div>

                        </div>
                    </div>

                    <div className={sbs.priceBox}>
                        <div className={sbs.conditionBox}>

                            <div className={sbs.smallConditionBox}>
                                <span className={`${sbs.for} ${sbs.pinkColor}`}>Reader</span>
                                <span className={`${sbs.type} ${sbs.pinkColor}`}>Basic</span>
                            </div>

                            <hr className={`${sbs.line} ${sbs.pinkBackground}`} />

                            <div className={sbs.seconthird}>
                                <span className={`${sbs.price} ${sbs.pinkColor}`}>$60</span>
                                <span className={`${sbs.monthly} ${sbs.pinkColor}`}>monthly</span>
                            </div>

                        </div>
                        <div className={sbs.benefitsBox}>
                            <div className={sbs.likeAndTxt}>
                                <Heart color='#CF4C75' />
                                <span className={sbs.spanTxt}>For those who are ready to create and earn</span>
                            </div>

                            <div className={sbs.likeAndTxt}>
                                <Heart color='#CF4C75' />
                                <span className={sbs.spanTxt}>Create your own series</span>
                            </div>

                            <div className={sbs.likeAndTxt}>
                                <Heart color='#CF4C75' />
                                <span className={sbs.spanTxt}>Engage and grow your audience</span>
                            </div>

                            <div className={sbs.likeAndTxt}>
                                <Heart color='#CF4C75' />
                                <span className={sbs.spanTxt}>Earn for your episode views</span>
                            </div>

                            <div className={sbs.likeAndTxt}>
                                <Heart color='#CF4C75' />
                                <span className={sbs.spanTxt}>40&nbsp;free episode moderations per month</span>
                            </div>

                            <div className={sbs.likeAndTxt}>
                                <Heart color='#CF4C75' />
                                <span className={sbs.spanTxt}>Whitelist access to&nbsp;new features</span>
                            </div>

                        </div>
                    </div>
                </div>
                <button onClick={()=>handleWaitListPopupOpen('reader')} className={sbs.btn} type='button'>Join Waitlist</button>

            </div>
        </section >
    );
};