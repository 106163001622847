import appStyles from './app.module.css';
import { Intro } from '../intro/intro';
import { IntroPlayer } from '../introPlayer/introPlayer';
import { FourZones } from '../FourZones/FourZones';
import { useDispatch, useSelector } from 'react-redux';
import { changePopupState } from '../../assets/services/actions/popup.jsx';
import { Series } from '../Series/Series';
import { FeelArticle } from '../FeelArticle/FeelArticle';
import { Categories } from '../Categories/Categories';
import { Subscriptions } from '../Subscriptions/Subscriptions';
import { TellStories } from '../TellStories/TellStories';
import { SubscriptionCards } from '../SubscriptionCards/SubscriptionCards';
import { Faq } from '../Faq/Faq';
import { Footer } from '../Footer/footer';
import { Modal } from '../modal/Modal'
import WaitlistPopup from '../WaitlistPopup/WaitlistPopup';
import { useState } from 'react';
import { sendMetriс } from '../../assets/utils/utils';

export const App = () => {
  // const preLoading = (state) => state.data.dataRequest;
  // const isLoading = useSelector(preLoading);
  // const navigate = useNavigate(); && navigate('/');
  // useEffect(() => {
  //   dispatch(getData());
  // }, [dispatch]);

  const [popups, setPopups] = useState({
    waitlist: false,
  })

  const [defaultUserType, setDefaultUserType] = useState('reader')

  function handleWaitListPopupOpen(user_type) {
    sendMetriс('reachGoal', 'OPEN_WAITLIST_POPUP')
    setDefaultUserType(user_type)
    setPopups(prevState => ({
      ...prevState,
      waitlist: true
    }))
  }
  // const isPopupOpen = (state) => state.popupState.isPopupOpened;
  // const isPopupOpened = useSelector(isPopupOpen);

  // const dispatch = useDispatch();

  // const popupCloseHandler = () => {
  //   dispatch(changePopupState(false));
  // }

  return (

    <div className={appStyles.main}>
      <div className={appStyles.mainContent}>
        <Intro handleWaitListPopupOpen={handleWaitListPopupOpen} />
        <IntroPlayer />
        <FourZones />
        <Series />
        <FeelArticle />
        <Categories />
        <Subscriptions handleWaitListPopupOpen={handleWaitListPopupOpen} />
        <TellStories handleWaitListPopupOpen={handleWaitListPopupOpen} />
        <SubscriptionCards />
        <Faq />
        <Footer />
        {/* {isPopupOpened &&
          <Modal popupCloseHandler={popupCloseHandler} />
        } */}
        <WaitlistPopup
          isOpen={popups.waitlist}
          setOpen={setPopups}
          popupName={'waitlist'}
          defaultUserType={defaultUserType}
        />
      </div>

    </div >
  )
};
