import sc from './subscriptionCards.module.css';
import React from 'react';
import { useDispatch } from 'react-redux';
import vector1 from '../../assets/images/subsCards/subsCardVector.webp';
import vector2 from '../../assets/images/subsCards/subsCardVector2.webp';
import girl1 from '../../assets/images/subsCards/subsGirl1.png';
import girl2 from '../../assets/images/subsCards/subsGirl2.png';

export const SubscriptionCards = () => {

    const dispatch = useDispatch();

    return (
        <section className={sc.section}>
            <div className={sc.circle}></div>
            <div className={sc.circle2}></div>
            <div className={sc.box}>

                <div className={`${sc.txtCard}`}>

                    <span className={sc.for}>for 2&nbsp;months</span>
                    <span className={sc.free}>Free</span>
                    <p className={sc.p}>Sign up&nbsp;and purchase an&nbsp;annual
                        Subscription at&nbsp;a&nbsp;discounted rate</p>

                </div>

                <div className={`${sc.cardWrapper} ${sc.mr60}`}>
                    <span className={sc.h}>Reader &middot; Advanced</span>

                    <div className={`${sc.card}`}>

                        <img src={vector1} alt="" className={sc.vector} />
                        <img src={girl1} alt="" className={sc.img} />

                        <div className={sc.insideCard}>

                            <div className={sc.priceBox}>
                                <span className={sc.crossed}>120</span>
                                <span className={sc.dollar}>$99</span>
                                <span className={sc.perYear}>per year</span>

                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${sc.cardWrapper}`}>
                    <span className={sc.h}>Author &middot; Pro</span>

                    <div className={`${sc.card}`}>

                        <img src={vector2} alt="" className={sc.vector} />
                        <img src={girl2} alt="" className={sc.img} />

                        <div className={sc.insideCard}>

                            <div className={sc.priceBox}>
                                <span className={sc.crossed}>720</span>
                                <span className={sc.dollar}>$599</span>
                                <span className={sc.perYear}>per year</span>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};